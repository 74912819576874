<script setup lang="ts">
import { CONFIG } from '~~/config';
import { useUserStore } from '~~/src/stores/users';

const userStore = useUserStore();

</script>

<template>
  <NuxtLayout name="dashboard">
    <div class="max-w-100 mx-center p-xl">
      <p class="h2">Docs</p>

      <p class="subtitle dimmed mt-s">Learn to use Respell in minutes.</p>

      <div class="has-bottom-border my-l" />

      <p class="h4">Intro</p>

      <p class="body dimmed mb-l">
        Welcome to Respell! This documentation page is the place where you’ll
        learn everything there is to know about Respell. Feel free to hop back
        and forth between different sections to learn what fits yours needs
        best. We also have a
        <NuxtLink to="/resource/tutorials"> Tutorials </NuxtLink> page where we
        walk you through building a spell step-by-step. We have an
        <NuxtLink to="/resource/whattheheckisai"> “AI for Dummies” </NuxtLink>
        page where we update you on everything there is to know about the AI
        space right now. For searching through common questions, visit our FAQs
        page.
      </p>

      <p class="h4">What is Respell?</p>

      <p class="body dimmed">
        Respell is a platform where you can explore and create spells. Spells
        are what we call workflow automations that are powered by AI (artificial
        intelligence). It’s a spell because its supposed to feel like magic!✨
        <br />
        <br />
        Respell has a templates page where you can browse spells created by the
        Respell team & community. There’s spells covering automations for
        anything from filtering through resumés all the way to creating beats
        from an album cover.
        <br />
        <br />

        Browse our <NuxtLink to="/templates"> templates page </NuxtLink> for
        inspiration on what you can create with Respell! Here’s a list of the
        categories you can filter by:
      </p>
      <img
        src="~assets/img/explore-categories.png"
        class="center-img"
        style="width: 150px"
      />
      <p class="body dimmed mb-l">
        Respell is also a tool to create spells, a.k.a workflow automations
        using AI. You can create spells from scratch or get a head start by
        using a template or cloning a spell from the templates page and build on
        top of that! For more information, see the “Building Spells” section.
      </p>

      <p class="h4">Using Spells</p>
      <p class="body dimmed mb-m">
        The Respell <NuxtLink to="/templates"> templates page </NuxtLink> is
        packed with potential use cases. On this page, you’ll see all the spells
        that have been verified and marked as public. You can filter by
        categories or search for a spell. When you click on a spell, you’ll be
        able to run it!
        <br />
        <br />
        Here’s an example by of our users. It’s a spell called
        <NuxtLink to="/spell/_n9RHcaq0WDdYyX5EYA84/"> beatware </NuxtLink>.
      </p>
      <img src="~assets/img/beatware.png" />
      <p class="body dimmed mb-l">
        To use this spell, you can fill out the fields and press “Run”. The
        output will be generated on the same page! <br />
        <br />
        If you like this spell, you can duplicate it to build on top of it or
        modify it. You can also bookmark it to save it in your
        <NuxtLink to="/spells"> spells </NuxtLink>. Or you can press the “Share”
        button to post to social media or send the link to your friends.
      </p>
      <p class="h4">Building Spells</p>
      <p class="body dimmed mb-m">
        There are many ways to start building a spell. You can start from a
        template or cloning a spell from the Templates page, or you can click
        the “New Spell” button from the
        <NuxtLink to="/"> Home </NuxtLink> page or
        <NuxtLink to="/spells"> Spells </NuxtLink> page.
        <br />
        <img
          src="~assets/img/save-spell.png"
          class="center-img"
          style="width: 450px"
        />
        From here, you’ll get redirector to our spell editor! This is a drag &
        drop no-code editor. To get started, click on a ‘input’ block and attach
        it to a model!
      </p>
      <img src="~assets/img/spell-editor.png" />
      <p class="body dimmed mb-l">
        All the blocks in our editor fit in 3 categories: basic blocks, data
        blocks, and models.
      </p>
      <p class="h4">Basic Blocks</p>
      <p class="body dimmed mb-m">
        Basic blocks include input block, output block, and a combine text
        block. Both input and output block automatically detect data types when
        connected to models.
        <br />
        The combine text block is one of the most powerful basic blocks. It
        allows you to connect inputs from various sources and use them as
        variable to combine text for a prompt! Here’s an example from our
        <NuxtLink to="/spell/dueAE6pLKqxgUz4P2aeGd">
          Movie Night Suggestion Spell </NuxtLink
        >:
      </p>
      <img src="~assets/img/movie-night.png" />
      <p class="body dimmed mb-m">
        In the example above, the combine text block is used as a “create genre
        prompt” block. It takes in 2 inputs: movie that the user likes, and the
        user’s favorite movie genre. These inputs were then used as variables to
        create a prompt for GPT-3 to generate a genre suggestion.
        <br />
        <br />
        Upon clicking the <strong>combine text</strong> block, you can use the
        inputs variable to create the prompt for a model block!
      </p>
      <img src="~assets/img/movie-night-prompt.png" class="mb-l" />
      <p class="h4">Data Blocks</p>
      <p class="body dimmed mb-m">
        There are currently 2 types of data blocks:
      </p>
      <ul class="body dimmed mb-l">
        <li>File to Text - converts PDF files into text.</li>
        <li>
          Web Search - conducts a web search from the given query and returns
          relevant text from the first page of search engine results.
        </li>
      </ul>
      <p class="h4">Models</p>
      <p class="body dimmed mb-m">
        Respell supports many of the mainstream AI models, and constantly adding
        more. Here’s a list of the models we currently support:
      </p>
      <ul class="body dimmed mb-l">
        <li>
          Text
          <ul>
            <li>GPT-3</li>
            <li>GPT-4</li>
            <li>Cohere Generate</li>
            <li>Cohere Summarize</li>
          </ul>
        </li>
        <li>
          Conversational
          <ul>
            <li>ChatGPT</li>
          </ul>
        </li>
        <li>
          Image
          <ul>
            <li>Stable Diffusion</li>
            <li>DALLE-2</li>
            <li>Kadinsky 2.1</li>
            <li>Generative Filling</li>
          </ul>
        </li>
        <li>
          Speech
          <ul>
            <li>Whisper</li>
            <li>Eleven Labs</li>
          </ul>
        </li>
        <!-- <li>
            Music
            <ul>
              <li>Riffusion</li>
            </ul>
          </li> -->
      </ul>
      <p class="h4">API</p>
      <p class="body dimmed mb-m">
        We provide an API for you to go beyond the published spell page. With an
        API, you can make the spell interface however you’d like!
        <br />
        <br />
        Here’s an example of how we used the Respell API to power an app:
        <NuxtLink href="https://resume-ranker.replit.app/">
          resume-ranker.replit.app/
        </NuxtLink>
        <br />
        <br />
        To use the API, your spell must be published. All you need to do is copy
        the code below and supply the inputs values for each block. The outputs
        will be based on the spell models' interactions with these inputs. To
        always run the latest version of the spell, omit the spell version ID.
      </p>
    </div>
  </NuxtLayout>
</template>

<style scoped>
.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 100%;
}

ul > li {
  margin-left: 20px;
  margin-bottom: 10px;
}

a {
  display: inline-block;
  text-decoration: underline;
}
</style>
